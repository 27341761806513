<template>
  <b-card class="shop-varaitions">
    <div class="row mb-1">
      <div class="col-3 mobile-col-6">
        <label>{{ $t('Status') }}</label>
        <b-form-select
          v-model="filterDates.status"
          :options="$superApp.appConstants.statusList()"
        />
      </div>
      <div class="col-3 text-left d-flex flex-wrap align-items-center">
        <button
          class="btn btn-primary mt-2"
          @click="filterData"
        >
          {{ $t('Submit') }}
        </button>
      </div>
    </div>
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="categoryRef"
          :button-label="$t('Add Variations')"
          :columns="categoryColumns"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="getApiEndpoint()"
          @add-new-info="showAddModal"
        />
      </b-col>
    </b-row>
    <b-modal
      v-model="showVariationModal"
      :no-close-on-backdrop="true"
      :title="dataInfo && dataInfo.id ? 'Edit Varaition' : 'Add Varaition'"
      size="lg"
      ok-only
      hide-footer
      :ok-title="$t('Save Changes')"
      @hidden="showVariationModal = false"
      @ok="saveData"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Title')">
            <b-form-input
              v-model="dataInfo.title"
              :class="{ 'is-invalid': !dataInfo.title && isDataSubmitted }"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Status')">
            <b-form-select
              v-model="dataInfo.status"
              :class="{ 'is-invalid': (dataInfo.status === '' || dataInfo.status === null || dataInfo.status === undefined) && isDataSubmitted }"
              :options="[
                {
                  text: 'Active',
                  value: '1',
                },
                {
                  text: 'Inactive',
                  value: '0',
                },
              ]"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group :label="$t('Variations')">
            <b-form-tags
              v-model="dataInfo.variation_values"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            v-model="dataInfo.multiple"
            switch
            inline
          >
            <span>{{ $t('Multi Select') }}</span>
          </b-form-checkbox>
        </b-col> -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            v-model="dataInfo.optional"
            switch
            inline
          >
            <span>{{ $t('Optional') }}</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <modal-footer
        :show-cancel="false"
        @ok="saveData"
      />
    </b-modal>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BFormGroup, BFormInput, BFormSelect, BModal, BFormCheckbox, BFormTags } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import ModalFooter from '@/@core/components/modal-footer/ModalFooter.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useStoreUi } from './useStore'

export default {
  components: {
    BRow,
    BCol,
    GoodDataTable,
    ModalFooter,
    BCard,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BModal,
    BFormTags,
  },
  data() {
    return {
      isDataSubmitted: false,
      filterDates: {},
      withPriceOptions: [
        { text: 'With Price', value: 1, disabled: false },
        { text: 'Without Price', value: 0, disabled: false },
      ],
      showVariationModal: false,
      dataInfo: {
        store_id: '',
        title: '',
        status: '',
        optional: 0,
        multiple: 0,
        id: 0,
        variation_values: [],
      },
      categoryColumns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Status',
          field: 'status',
          align: 'center',
          rField: 'status_text',
          useResolver: true,
          useRenderer: true,
          renderer: props => (props.status === 1 ? 'Active' : 'Inactive'),
        },
        {
          label: 'Variations',
          field: 'variation_values',
          rField: 'variation_values_text',
          useResolver: true,
          useRenderer: true,
          renderer: props => (props.variation_values ? props.variation_values.join(', ') : ''),
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.editCategory(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('status'),
              text: 'Change Status',
              action: props => {
                this.$swal({
                  title: this.$t('You want to change the status?'),
                  iconHtml: this.$helpers.swalIcon('confirm.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.changeCategoryStatus(props)
                  }
                })
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete this varaition?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.deleteVariation(props)
                  }
                })
              },
            },
          ],
        },
      ],
    }
  },
  computed: {
    storeInfo() {
      return window.SuperApp.getters.userInfo()
    },
  },
  methods: {
    showAddModal() {
      Object.keys(this.dataInfo).forEach(x => {
        this.dataInfo[x] = ''
      })
      this.dataInfo.variation_values = []
      this.showVariationModal = true
      this.isDataSubmitted = false
    },
    filterData() {
      const payload = []
      if (this.filterDates.status) payload.push(`status=${this.filterDates.status}`)
      this.$refs.categoryRef.loadItems(payload.join('&'))
    },
    updateItemsForShop() {
      const { updateVariation } = useStoreUi()
      showLoader()
      const formData = this.getFormData()
      // eslint-disable-next-line no-underscore-dangle
      formData._method = 'PATCH'
      updateVariation({
        query: this.dataInfo.id,
        data: formData,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.categoryRef.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            this.showVariationModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    saveData() {
      this.isDataSubmitted = true
      setTimeout(() => {
        if (window.SuperApp.validations.anyErrorInModal()) {
          return
        }
        this.showVariationModal = false
        this.isDataSubmitted = false
        if (this.dataInfo.id) {
          this.updateItemsForShop()
        } else {
          this.addItemsForShop()
        }
      })
    },
    getFormData() {
      const dataInfo = window.SuperApp.actions.cloneDeep(this.dataInfo)
      dataInfo.store_id = window.SuperApp.getters.userInfo().id
      Object.keys(dataInfo).forEach(x => {
        if (['status', 'optional', 'multiple'].includes(x)) {
          dataInfo[x] = Number(dataInfo[x])
        }
      })
      return dataInfo
    },
    addItemsForShop() {
      const { createVaraition } = useStoreUi()
      showLoader()
      const formData = this.getFormData()
      createVaraition(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.categoryRef.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            this.showVariationModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          this.showVariationModal = true
          showErrorNotification(this, error)
        })
    },
    editCategory(props) {
      showLoader()
      const { getVariationById } = useStoreUi()

      getVariationById(props.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.shopItem = data.responseData
            Object.keys(this.dataInfo).forEach(x => {
              this.dataInfo[x] = this.shopItem[x]
            })
            const boolData = ['optional', 'multiple']
            boolData.forEach(x => {
              this.dataInfo[x] = Boolean(this.dataInfo[x])
            })
            this.showVariationModal = true
          }
          hideLoader()
        })
        .catch(error => {
          this.showVariationModal = true
          showErrorNotification(this, error)
        })
    },
    deleteVariation(props) {
      showLoader()
      const { deleteVariation } = useStoreUi()

      deleteVariation(props.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.categoryRef.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            this.showVariationModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          this.showVariationModal = true
          showErrorNotification(this, error)
        })
    },
    changeCategoryStatus(props) {
      const payload = window.SuperApp.actions.cloneDeep(props)
      payload.status = payload.status === 0 ? 1 : 0
      this.dataInfo = payload
      this.updateItemsForShop()
    },
    getApiEndpoint() {
      return '/shop/variations/list'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
